<template>
  <Component :is="as">
    {{ computedPerks }}
  </Component>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useDataFormatter } from '~/composables/useDataFormatter';

interface Props {
  perks: string[]
  as?: 'span' | 'div' | 'p'
}
const props = withDefaults(defineProps<Props>(), {
  as: 'span',
});
const { perksFormatter } = useDataFormatter();
const computedPerks = computed(() => perksFormatter(props.perks));
</script>
